<template>
  <div class="height1">
    <!--      头部      -->
    <div class="iq-top-navbar">
      <el-page-header @back="$router.go(-1)" content="就诊记录"> </el-page-header>
    </div>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <el-descriptions class="margin-top" :column="3" border>
          <el-descriptions-item label="患者姓名">{{ patientDetail.name }}</el-descriptions-item>
          <el-descriptions-item label="年龄">{{ patientDetail.age }}岁</el-descriptions-item>
          <el-descriptions-item label="手机号">{{ patientDetail.phone }}</el-descriptions-item>
          <el-descriptions-item label="身份证号">{{ patientDetail.idNo }}</el-descriptions-item>
          <el-descriptions-item label="联系地址">{{ patientDetail.address }}</el-descriptions-item>
        </el-descriptions>
        <el-table :data="diagnoseList" stripe style="width: 100%; margin-top: 10px" height="calc(100% - 120px)" :header-cell-style="{ background: '#F5F7FC' }">
          <el-table-column prop="diseaseDiagnose" label="诊断"> </el-table-column>
          <el-table-column prop="addTime" label="就诊时间">
            <template slot-scope="scope">
              {{ scope.row.addTime | timefilters }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="300" align="center">
            <template #default="scope">
              <span class="warning optionBtn" @click="toOrderInfo(scope.row)">详情</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Outpatient } from '@/components/DrugDomain/Outpatient.js'
import bread from '../../../components/common/breadcrumb'
import { Patient } from '@/components/HospitalDomain/Patient/Patient.js'

export default {
  name: 'Patient',
  components: {
    bread,
  },
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    var patient = new Patient(this.TokenClient, this.Services.Authorization)
    return {
      OutpatientDomain: outpatient,
      patientDomain: patient,
      id: this.$route.query.id,
      tableData: [],
      searchForm: {
        statrTime: '',
        endTime: '',
        keyWord: '',
        datepicker: '',
      },
      patientDetail: {},
      diagnoseList: [],
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getOrderDiagnoseDetail(item) {
      var _this = this
      return new Promise((resolve, reject) => {
        _this.OutpatientDomain.getOrderDiagnoseDetail(
          item.id,
          function (data) {
            resolve(data.data)
          },
          function (error) {
            let jsonObj = JSON.parse(error.msg)
            _this.$message({
              message: '患者没有订单',
              type: 'warning',
            })
            resolve(false)
            console.log(error)
          }
        )
      })
    },
    async toOrderInfo(item) {
      let res = await this.getOrderDiagnoseDetail(item)
      if (res) {
        this.$router.push({
          path: '../Outpatient/OrderInfo',
          query: {
            type: 1, //已收费
            orderId: res.id,
          },
        })
      }
    },
    getDetail() {
      var _this = this
      _this.patientDomain.GetDetail(
        _this.id,
        function (data) {
          _this.patientDetail = data.data
          _this.diagnoseList = data.data.patientDiagnose
          _this.cursub = _this.diagnoseList[0].id
          // _this.getcur(_this.cursub)
        },
        function (err) {}
      )
    },
    SearchInputKeydown() {
      this.PageIndex = 1
      this.GetList()
    },
    search() {
      console.log(this.searchForm.datepicker)
      this.PageIndex = 1
      this.GetList()
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.GetList()
    },
    GetList() {
      var _this = this
      _this.tableData = []
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.patientDomain.GetOrganizationPatientList(
        item.startTime,
        item.endTime,
        item.keyWord,
        _this.PageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.DataTotal = data.data.dataTotal
        },
        function (error) {
          console.log(error)
        }
      )
    },
    toRecord(item) {
      this.$router.push({
        path: '/Patient/DiagnosticRecord',
        query: {
          id: item.id,
        },
      })
    },
    toAdd() {
      this.$router.push({
        path: '/Patient/AddPatient',
        query: { title: '新增患者' },
      })
    },
    toEdit(item) {
      this.$router.push({
        path: '/Patient/AddPatient',
        query: {
          id: item.id,
          title: '编辑患者',
        },
      })
    },
    addDR(item) {
      this.$router.push({
        path: '/Patient/AddDiagnostic',
        query: {
          id: item.id,
        },
      })
    },
    toList(item) {
      this.$router.push({
        path: '/Patient/DiagnosticRecord',
        query: {
          id: item.id,
        },
      })
    },
    addRX(item) {
      this.$router.push({
        path: '/Patient/Prescribing',
        query: {
          id: item.id,
        },
      })
    },
    remove(item) {
      var _this = this
      _this
        .$confirm('是否删除此患者信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // type: 'warning'
        })
        .then(() => {
          _this.patientDomain.patientRemove(
            item.id,
            function (data) {
              _this.$message({
                type: 'success',
                message: '删除成功!',
              })
              _this.GetList()
            },
            function (err) {
              console.log(err)
            }
          )
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped>
/*content*/

.table-top {
  /* margin-bottom: 15px; */
}

.ParameterForm .el-input {
  margin-right: 10px;
  width: 150px;
  vertical-align: top;
}
</style>
